






























































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import {
  ResumeInterviewPayload,
  StartInterviewPayload
} from "@/store/modules/recruiter/interfaces";
import {
  RESUME_CANDIDATE_INTERVIEW,
  START_CANDIDATE_INTERVIEW,
  UPDATE_INTERVIEW_STATUS
} from "@/store/modules/recruiter/constants";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { generate_random_key, wait_until } from "@/utils/global";

import {
  Interview,
  SpeechRecognitionResult
} from "@/interfaces/responses/interviews/interviews";
import { InterviewRoles } from "@/interfaces/candidate/candidate_interview";
import moment from "moment";
import DigitalHuman from "@/components/candidate/interviews/DigitalHuman.vue";
import {
  DIGITAL_HUMAN_LOADING,
  HYGEN_SESSION_ID,
  RESET_DIGITAL_HUMAN,
  SESSION_INITIALIZED_SUCCESSFULLY,
  SPEAK_HYGEN_BOT,
  STOP_HYGEN_SESSION
} from "@/store/modules/digital_human/constants";
import {
  CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT,
  CANDIDATE_INTERVIEW_DETAILS,
  CANDIDATE_INTERVIEW_HISTORY,
  CANDIDATE_INTERVIEW_ID,
  CANDIDATE_INTERVIEW_STOP,
  RESET_CANDIDATE_INTERVIEW_STATE
} from "@/store/modules/candidate_interview/constants";

export default Vue.extend({
  name: "InterviewV2",
  components: {
    GoBackHeader,
    DigitalHuman
  },
  data() {
    return {
      // Thank you message mp3 after interview completion
      interview_complete_msg:
        "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/interview_end_msg.mp3",
      data_loading: false, // To check if data is loading or not
      // Thank you message after interview completion
      interview_end_text:
        "Thank you for participating in the interview process. " +
        "We appreciate your time and interest in our company. " +
        "Our team will review your application and interview performance thoroughly. " +
        "If there are any further updates or next steps, we will reach out to you accordingly. " +
        "Thank you again for your time, and we wish you the best of luck in your job search.",
      interview_title: "Interviewing for ", // Interview title
      bot_ans_loading: false, // To check bot ans is fetching from the server or not
      speech_mode: false, // To check user is speaking or not
      bot_speaking: true, // To check bot is speaking or not
      bot_image: require("@/assets/images/female-bot.png"), // Bot image
      interview_completed: false,
      prompt: "",
      transcription: "",
      interview_status: Interview.Status.TechnicalInterview,
      generated_voices: new Set<string>(),
      recognition: null as typeof window.webkitSpeechRecognition | null
    };
  },
  computed: {
    ...mapGetters("candidate_interviews", {
      get_interview_history: CANDIDATE_INTERVIEW_HISTORY,
      candidate_interview_details: CANDIDATE_INTERVIEW_DETAILS,
      get_interview_id: CANDIDATE_INTERVIEW_ID
    }),
    ...mapGetters("digital_human", {
      digital_human_loading: DIGITAL_HUMAN_LOADING,
      hygen_session_successfully_initialized: SESSION_INITIALIZED_SUCCESSFULLY,
      hygen_session_id: HYGEN_SESSION_ID
    }),
    InterviewRoles() {
      return InterviewRoles;
    },
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    })
  },
  watch: {
    hygen_session_successfully_initialized(val) {
      if (val) this.initialize_data();
    }
  },
  async mounted() {
    this.init_speak_recognition();
    window.addEventListener("beforeunload", this.beforeUnload, {
      passive: true
    });
    this.interview_title += this.candidate_interview_details?.job_title;
    await this.update_chat_cursor();
  },

  methods: {
    ...mapMutations("candidate_interviews", {
      set_interview_history: CANDIDATE_INTERVIEW_HISTORY,
      set_ans_transcript: CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT,
      set_interview_stop_status: CANDIDATE_INTERVIEW_STOP,
      reset_candidate_interview: RESET_CANDIDATE_INTERVIEW_STATE
    }),
    ...mapMutations("digital_human", {
      reset_digital_human: RESET_DIGITAL_HUMAN
    }),
    ...mapActions("digital_human", {
      speak_hygen_bot: SPEAK_HYGEN_BOT,
      stop_hygen_session: STOP_HYGEN_SESSION
    }),
    generate_random_key,
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("recruiter", {
      start_interview: START_CANDIDATE_INTERVIEW,
      resume_interview: RESUME_CANDIDATE_INTERVIEW,
      update_interview_status: UPDATE_INTERVIEW_STATUS
    }),
    async initialize_data() {
      // Get initial questions
      const text = await this.init_interview();
      await this.speak_hygen_bot({
        session_id: this.hygen_session_id,
        text: text
      }).finally(() => {
        this.bot_speaking = false;
      });
    },

    // Function to navigate back to the interviews' page
    async invalid_interview_error() {
      // Set interview completed to false so that complete_interview function won't be called after media recorder stop
      this.interview_completed = false;
      // this.stop_media_recorder(); // Stop media recorder
      this.set_interview_stop_status(true);
      this.set_root_error("Interview Details Not Found"); // Set root error
      this.reset_digital_human(); // Reset digital human
      this.reset_candidate_interview(); // Reset candidate interview
      await this.$router.push("/candidate/interviews"); // Navigate to the interviews' page
    },
    // Function to get initial interview questions
    // Push initial interview questions to interview history
    // Return initial interview questions file
    async init_interview() {
      this.bot_ans_loading = true;
      // Get the initial interview questions
      const payload: StartInterviewPayload = {
        interview_id: parseInt(this.$route.params.interview_id),
        candidate_id: this.get_user.id,
        interview_type: this.interview_status
      };
      const result = await this.start_interview(payload); // Api call to get initial interview questionsinterview
      // If failed to get initial interview questions => navigate to the interviews' page
      if (!result) {
        await this.invalid_interview_error();
        return;
      }

      this.bot_ans_loading = false; // Set bot ans loading to false
      // Push initial interview questions to interview history
      this.push_interview_history_obj(
        result.text,
        moment(result.created_at).format("ddd, h:mm A").toString(),
        this.bot_image
      );
      this.bot_ans_loading = false;
      return result.text; // Return initial interview questions file
    },

    // Function to format interview date and return it in string format
    format_interview_date(date: number = moment.now()): string {
      return moment(date).format("ddd, h:mm A").toString();
    },
    // Function to push interview history object to interview history array
    push_interview_history_obj(
      content: string,
      created_at: string,
      picture: string,
      role: InterviewRoles = InterviewRoles.BOT,
      id: number = generate_random_key()
    ) {
      this.set_interview_history([
        {
          content,
          role,
          picture,
          created_at,
          id
        }
      ]);
    },
    /**
     * Function to handle user speech
     * @param {string} result => user voice
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handle_user_speech(result: any) {
      const transcript = Array.from(result.results as SpeechRecognitionResult[])
        .map((resultItem: SpeechRecognitionResult) => {
          return resultItem[0].transcript;
        })
        .join("");
    },
    // Function to send user response
    // If speech mode is active => stop speech recognition
    // Set bot speaking to true
    // Set bot ans loading to true
    // Get user response
    // Push user response to interview history
    // If interview id exist => resume interview
    async send_user_response() {
      // If speech mode is active => stop speech recognition
      if (this.speech_mode) {
        this.speech_mode = false;
        this.$emit("speech_recognition", false);
      }
      this.bot_speaking = true;
      this.bot_ans_loading = true;
      const transcript = this.prompt; // Get user response
      this.set_ans_transcript("");
      this.prompt = "";
      // Push user response to interview history
      this.push_interview_history_obj(
        transcript,
        this.format_interview_date(),
        this.get_user.avatar_uri,
        InterviewRoles.USER
      );

      const payload: ResumeInterviewPayload = {
        answer: transcript,
        interview_id: this.get_interview_id,
        interview_type: this.interview_status
      };
      const result = await this.resume_interview(payload); // Api call to get bot ans from user response
      // If failed to get bot ans from user response => navigate to the interviews' page
      if (!result) {
        //     this.interview_completed = false;
        //     this.stop_media_recorder();
        //     this.set_root_error(
        //       this.$t("candidate.interview.resume-interview-error")
        //     );
        //     await this.$router.push("/candidate/interviews");
        //     return;
      }
      // Check if the interview is over
      const complete = result.text
        .toLowerCase()
        .includes("thank you for your time");
      // If interview overed
      if (this.interview_status === Interview.Status.HrInterview && complete) {
        await this.finish_interview();
      }
      // If interview not overed => play bot ans
      else {
        // Push bot ans to interview history
        if (!complete) {
          this.push_interview_history_obj(
            result.text,
            this.format_interview_date(),
            this.bot_image
          );
          await this.update_chat_cursor(); // Update chat cursor
          await this.speak_hygen_bot({
            session_id: this.hygen_session_id,
            text: result.text
          }).finally(() => {
            this.bot_speaking = false;
          });
        } else if (
          this.interview_status === Interview.Status.TechnicalInterview
        ) {
          this.update_interview_status({
            interview_id: this.get_interview_id,
            action: Interview.Status.HrInterview,
            filename: ""
          });
          this.interview_status = Interview.Status.HrInterview;
          this.set_interview_history([]);
          await this.initialize_data();
          this.set_root_notification(
            this.$t("candidate.interview.hr-interview")
          );
        }
      }
      this.bot_ans_loading = false;
    },
    // Function to start speech recognition
    // If speech mode is active => stop speech recognition
    // If speech mode is inactive => start speech recognition
    speak_config() {
      if (!this.speech_mode) {
        this.$emit("speech_recognition", true);
        this.recognition.start();
      } else {
        this.$emit("speech_recognition", false);
        this.recognition.stop();
      }
      this.speech_mode = !this.speech_mode;
    },
    async finish_interview() {
      this.interview_completed = true;
      this.set_interview_stop_status(true);
      this.$emit("interview_finished", true);
      const res = await this.update_interview_status({
        interview_id: this.get_interview_id,
        action: Interview.Status.Finished,
        filename: ""
      });
      if (this.hygen_session_id) {
        this.stop_hygen_session({
          session_id: this.hygen_session_id
        });
      }
      window.removeEventListener("beforeunload", this.beforeUnload);
      this.reset_digital_human(); // Reset digital human
      if (!res) {
        this.set_root_error(this.$t("errors.internal"));
        await this.$router.push("/candidate/interviews");
        return;
      }
      this.set_root_notification("Interview Completed Successfully");
      await this.$router.push("/candidate/interviews");
    },
    text_field_msg() {
      if (!this.speech_mode) {
        return this.$t("candidate.interview.speak").toString();
      } else return this.$t("candidate.interview.listening").toString();
    },
    tooltip_msg() {
      if (!this.speech_mode) {
        return this.$t("candidate.interview.start-mic").toString();
      } else return this.$t("candidate.interview.stop-mic").toString();
    },
    async update_chat_cursor() {
      await wait_until(1000);
      const box = this.$refs.chat_box as HTMLDivElement;
      if (box) box.scrollTop = box.scrollHeight;
    },
    beforeUnload(event: BeforeUnloadEvent) {
      const confirmationMessage =
        "Are you sure you want to cancel the interview?";
      event.returnValue = confirmationMessage;
      localStorage.setItem("interview_cancel", this.get_interview_id);
    },
    init_speak_recognition() {
      const speech_recognition = window.webkitSpeechRecognition;
      this.recognition = new speech_recognition(); // Create a new speech recognition object
      this.recognition.interimResults = true; // Set interim results to true so that we can get the result before the user stops speaking
      this.recognition.continuous = true; // Set continuous to true so that we can get the result continuously
      this.recognition.onresult = (event: any) => {
        const results = Array.from(
          // eslint-disable-next-line no-undef
          event.results as SpeechRecognitionResult[]
        );
        let last_final_result = null;
        for (let i = results.length - 1; i >= 0; i--) {
          // Iterate in reverse
          if (results[i].isFinal) {
            last_final_result = results[i];
            break; // Found it, no need to continue
          }
        }

        if (last_final_result) {
          const transcript = last_final_result[0].transcript;
          if (transcript) {
            const formatted = transcript.trim().toLowerCase();
            if (!this.generated_voices.has(formatted)) {
              this.prompt += " " + formatted;
              this.generated_voices.add(formatted);
            }
          }
        }
      };
    },
    cancel_prompt() {
      this.prompt = "";
      this.generated_voices.clear();
      if (this.speech_mode) this.speak_config();
      this.recognition.stop();
    }
  },
  beforeDestroy() {
    this.reset_digital_human(); // Reset digital human
    this.reset_candidate_interview(); // Reset candidate interview
    if (this.hygen_session_id) {
      this.stop_hygen_session({
        session_id: this.hygen_session_id
      });
    }
    window.removeEventListener("beforeunload", this.beforeUnload);
  }
});
