import { InterviewRoles } from "@/interfaces/candidate/candidate_interview";

export namespace Interview {
  export interface Response {
    id: number;
    candidate_id: number;
    interviewed_by: number;
    created_at: Date;
    job_id: number;
    interview_status: string;
  }

  export interface ListingByRecruiterResponse {
    results: Response[];
    total: number;
  }
  export interface GetInterviewResponse {
    id: number;
    candidate_id: number;
    interviewed_by: number;
    created_at: Date;
    job_id: number;
    interview_status: string;
    uploaded_path: {
      uploadPath: string;
      text: string;
    };
  }
  export interface InterviewHistoryResponse {
    id: number;
    role: InterviewRoles;
    content: string;
    created_at: string;
  }
  export enum Status {
    INVITE_SENT = "invite_sent",
    Finished = "finished",
    TechnicalInterview = "technical_interview",
    HrInterview = "hr_interview",
    CANCELLED = "CANCELLED"
  }
}

export interface SpeechRecognitionResult {
  [key: number]: SpeechRecognitionAlternative;
  length: number;
  isFinal: boolean;
}

interface SpeechRecognitionAlternative {
  confidence: number;
  transcript: string;
}
